/**
 * 创建核销码规格弹窗
 */

 import React from 'react';
 import { connect } from 'react-redux';
 import { reduxForm } from 'redux-form'
 import { Dialog, FormGroupList, Input, } from 'tyb';
 import { FormGroupField } from 'tyb';
 import { NewSelect  } from 'ucode-components';
 const { cancelverifyCodeTemplateList, submitCancelverifyCodeTemplateList, initSubmitCancelverifyCodeTemplateList } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
 const { domainConfigsByCodeSourceType } = iceStarkData.store.get('commonAction')?.domainConfigs;
 import './index.scss';
 
 
 const validate = values => {
     let startTime = values.startTime;
     let endTime = values.endTime;
     if (typeof (startTime) === 'string') {
         startTime = new Date(startTime)
     }
     if (typeof (endTime) === 'string') {
         endTime = new Date(endTime)
     }
 
     const errors = {};
     if (!values.templateConfigId || (values.templateConfigId + '').trim() === '') {
         errors.templateConfigId = '请选择核销码配置'
     }
 
     if (!values.name || (values.name + '').trim() === '') {
         errors.name = '请输入核销码规格名称'
     } else if (values.name.trim().length > 30) {
         errors.name = '长度不超过30个字符';
     }
 
     if (!values.eseDomainId || (values.eseDomainId + '').trim() === '') {
         errors.eseDomainId = '请选择码域名'
     }
 
     return errors;
 }
 
 
 @connect(
     state => ({
         // domainConfigsByEseIdSelect: state.domainConfigs.domainConfigsByCodeSourceTypeSelect,   // 码域名下拉框
         // cancelverifyCodeTemplateListSelect: state.codePrintingConfig.cancelverifyCodeTemplateListSelect   // 核销码配置下拉框内容
         submitCancelverifyCodeStatus: state.codePrintingConfig.submitCancelverifyCodeStatus
     }),
     {
         // domainConfigsByEseId: domainConfigsByCodeSourceType.REQUEST,   // 获取码域名接口
         // getCancelverifyCodeTemplateList: cancelverifyCodeTemplateList.REQUEST,   // 获取核销码配置接口
         submitCancelverifyCodeTemplateList: submitCancelverifyCodeTemplateList.REQUEST,   // 创建编辑核销码规格接口
         initSubmitCancelverifyCodeTemplateList: initSubmitCancelverifyCodeTemplateList
     }
 )
 class CancelverifyCode extends React.Component {
     constructor(props) {
         super(props);
         this.state = {}
     }
 
     // 初始值，form的内容在这里加载cancelverifyInfo，效果：点击编辑按钮弹窗里有内容
     componentDidMount() {
         this.props.initialize(this.props.cancelverifyInfo)
     }
     
     componentDidUpdate() {
         const { submitCancelverifyCodeStatus } = this.props;
         // console.log(submitCancelverifyCodeStatus, 'submitCancelverifyCodeStatus-------');
         // 在确定里调用父亲里写的关闭方法，关闭弹窗
         if (submitCancelverifyCodeStatus == 'SUCCESS') {
             this.props.cancel();
             this.props.initSubmitCancelverifyCodeTemplateList();
         }
     }
 
     modalOk = (values) => {
         // console.log('点击了确定-----', values.name, values.templateConfigId, values.eseDomainId)
         if (this.props.cancelverifyCodeId) {
             this.props.submitCancelverifyCodeTemplateList({ data: { templateConfigId: values.templateConfigId, name: (values.name + '').trim(), eseDomainId: values.eseDomainId, id: this.props.cancelverifyCodeId }, listPharams: { params: this.props.params } })
         } else {
             this.props.submitCancelverifyCodeTemplateList({ data: { templateConfigId: values.templateConfigId, name: (values.name + '').trim(), eseDomainId: values.eseDomainId }, listPharams: { params: this.props.params } })
         }
     };
 
 
     render() {
         const { titleCancelVerifyCode, domainConfigsByEseIdSelect, cancelverifyCodeTemplateListSelect, handleSubmit } = this.props;
         return <Dialog
             className='codeCodeverifyForm'
             title={titleCancelVerifyCode == 'add' ? '新增核销码规格' : '编辑核销码规格'}
             visible={true}   // 组件里是默认打开弹窗的，通过父亲来控制是否显影
             style={{ width: 520 }}
             okText="确认"
             cancelText="取消"
             autoPosition={true}
             onOk={handleSubmit(this.modalOk)}
             onCancel={this.props.cancel}   // 从父亲拿到的cancel方法
         >
             <FormGroupList>
                 <FormGroupField
                     name='templateConfigId'
                     label="核销码配置"
                     style={{ width: 330 }}
                     component={NewSelect}
                     placeholder={'请选择'}
                     list={cancelverifyCodeTemplateListSelect}
                     required
                     disabled={titleCancelVerifyCode == 'edit' ? true : false}
                 />
                 <FormGroupField
                     name='name'
                     label="核销码规格名称"
                     component={Input}
                     required
                 />
                 <FormGroupField
                     name='eseDomainId'
                     label="码域名"
                     style={{ width: 330 }}
                     component={NewSelect}
                     placeholder={'请选择'}
                     list={domainConfigsByEseIdSelect}
                     required
                     disabled={titleCancelVerifyCode == 'edit' ? true : false}
                 />
             </FormGroupList>
         </Dialog>
     }
 }
 
 const CancelverifyCode1 = reduxForm({
     form: 'CodePrintingConfigDialog',
     validate
 })(CancelverifyCode);
 export default CancelverifyCode1;
